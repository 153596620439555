import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

export default function Aside() {
    const [openMenus, setOpenMenus] = useState({});
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const asideRef = useRef(null);

    var que = localStorage.getItem('us');
    if(que!= null){
        que = que.replace(/"/g,'');
    }

    useEffect(() => {
        // Restaurar el estado del menú al cargar
        const savedState = localStorage.getItem('sidebarState');
        if (savedState === 'collapsed') {
            setSidebarCollapsed(true);
            document.body.classList.add('sidebar-collapse');
        }

        // Event listener para cerrar el menú al hacer click fuera
        const handleClickOutside = (event) => {
            if (asideRef.current && !asideRef.current.contains(event.target)) {
                // Si el click fue en el botón de toggle, no hacemos nada
                if (event.target.closest('[data-widget="pushmenu"]')) {
                    return;
                }
                // Si estamos en móvil y el menú está abierto, lo cerramos
                if (window.innerWidth <= 768 && !document.body.classList.contains('sidebar-collapse')) {
                    document.body.classList.add('sidebar-collapse');
                    document.body.classList.remove('sidebar-open');
                    setSidebarCollapsed(true);
                }
                // Cerrar todos los submenús
                setOpenMenus({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    const toggleMenu = (menuId) => {
        setOpenMenus(prev => {
            const newState = { ...prev };
            // Cerrar otros menús si el que se está abriendo estaba cerrado
            if (!prev[menuId]) {
                Object.keys(newState).forEach(key => {
                    newState[key] = false;
                });
            }
            newState[menuId] = !prev[menuId];
            return newState;
        });
    };

    const toggleSidebar = () => {
        const newState = !sidebarCollapsed;
        setSidebarCollapsed(newState);
        if (newState) {
            document.body.classList.add('sidebar-collapse');
            document.body.classList.remove('sidebar-open');
        } else {
            document.body.classList.remove('sidebar-collapse');
        }
        localStorage.setItem('sidebarState', newState ? 'collapsed' : 'expanded');
    };

    const handleNavigation = (path, e) => {
        e.preventDefault();
        // Si estamos en móvil, cerrar el sidebar
        if (window.innerWidth <= 768) {
            document.body.classList.add('sidebar-collapse');
            document.body.classList.remove('sidebar-open');
        }
        // Navegar a la ruta y refrescar la página
        window.location.href = path;
    };

    return (
        <aside ref={asideRef} id="asi" className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="#" className="brand-link">
                <img
                    src="../dist/img/AdminLTELogo.png"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: ".8" }}
                />
                <span className="brand-text font-weight-light">App. San Vicente</span>
            </Link>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <i className="fas c fa-user-circle" alt="User Image"></i>
                    </div>
                    <div className="info">
                        <Link to="#" className="d-block">{que}</Link>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" role="menu" data-accordion="false">
                        <li id="boxx" className={`nav-item ${openMenus['boxx'] ? 'menu-open menu-is-opening' : ''}`}>
                            <Link to="#" className="nav-link" onClick={() => toggleMenu('boxx')}>
                                <i className="nav-icon fas fa-edit" />
                                <p>
                                    Ingresar
                                    <i className={`right fas fa-angle-left ${openMenus['boxx'] ? 'rotate-90' : ''}`} />
                                </p>
                            </Link>
                            <ul className={`nav nav-treeview ${openMenus['boxx'] ? 'ver' : ''}`}>
                                <li className="nav-item">
                                    <Link to="/contenido" className="nav-link" onClick={(e) => handleNavigation('/contenido', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Ingresar Guia/Vale</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li id="boxxc" className={`nav-item ${openMenus['boxxc'] ? 'menu-open menu-is-opening' : ''}`}>
                            <Link to="#" className="nav-link" onClick={() => toggleMenu('boxxc')}>
                                <i className="nav-icon fas fa-edit" />
                                <p>
                                    Crear
                                    <i className={`right fas fa-angle-left ${openMenus['boxxc'] ? 'rotate-90' : ''}`} />
                                </p>
                            </Link>
                            <ul className={`nav nav-treeview ${openMenus['boxxc'] ? 'ver' : ''}`}>
                                <li className="nav-item">
                                    <Link to="/distancias" className="nav-link" onClick={(e) => handleNavigation('/distancias', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Ingresar Distancia</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/materiales" className="nav-link" onClick={(e) => handleNavigation('/materiales', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Crear Materiales</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/conductores" className="nav-link" onClick={(e) => handleNavigation('/conductores', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Crear Conductor</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/patentes" className="nav-link" onClick={(e) => handleNavigation('/patentes', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Crear Patente</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ubicaciones" className="nav-link" onClick={(e) => handleNavigation('/ubicaciones', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Crear Ubicacion</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/destinos" className="nav-link" onClick={(e) => handleNavigation('/destinos', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Crear Destino</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/subi" className="nav-link" onClick={(e) => handleNavigation('/subi', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Crear SubDestino</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li id="boxx2" className={`nav-item ${openMenus['boxx2'] ? 'menu-open menu-is-opening' : ''}`}>
                            <Link to="#" className="nav-link" onClick={() => toggleMenu('boxx2')}>
                                <i className="nav-icon fas fa-gas-pump" />
                                <p>
                                    Petroleo
                                    <i className={`right fas fa-angle-left ${openMenus['boxx2'] ? 'rotate-90' : ''}`} />
                                </p>
                            </Link>
                            <ul className={`nav nav-treeview ${openMenus['boxx2'] ? 'ver' : ''}`}>
                                <li className="nav-item">
                                    <Link to="/petroleo" className="nav-link" onClick={(e) => handleNavigation('/petroleo', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Asignar Petroleo Camión</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/detalleP" className="nav-link" onClick={(e) => handleNavigation('/detalleP', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Detalle Petroleo</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li id="boxx3" className={`nav-item ${openMenus['boxx3'] ? 'menu-open menu-is-opening' : ''}`}>
                            <Link to="#" className="nav-link" onClick={() => toggleMenu('boxx3')}>
                                <i className="nav-icon fas fa-circle-dollar-to-slot" />
                                <p>
                                    Gastos
                                    <i className={`right fas fa-angle-left ${openMenus['boxx3'] ? 'rotate-90' : ''}`} />
                                </p>
                            </Link>
                            <ul className={`nav nav-treeview ${openMenus['boxx3'] ? 'ver' : ''}`}>
                                <li className="nav-item">
                                    <Link to="/gastos" className="nav-link" onClick={(e) => handleNavigation('/gastos', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Ingresar Gastos</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li id="boxx4" className={`nav-item ${openMenus['boxx4'] ? 'menu-open menu-is-opening' : ''}`}>
                            <Link to="#" className="nav-link" onClick={() => toggleMenu('boxx4')}>
                                <i className="nav-icon fas fa-truck" />
                                <p>
                                    Producción
                                    <i className={`right fas fa-angle-left ${openMenus['boxx4'] ? 'rotate-90' : ''}`} />
                                </p>
                            </Link>
                            <ul className={`nav nav-treeview ${openMenus['boxx4'] ? 'ver' : ''}`}>
                                <li className="nav-item">
                                    <Link to="/produccion" className="nav-link" onClick={(e) => handleNavigation('/produccion', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Conductores</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li id="boxx5" className={`nav-item ${openMenus['boxx5'] ? 'menu-open menu-is-opening' : ''}`}>
                            <Link to="#" className="nav-link" onClick={() => toggleMenu('boxx5')}>
                                <i className="nav-icon fas fa-chart-column" />
                                <p>
                                    Estadisticas
                                    <i className={`right fas fa-angle-left ${openMenus['boxx5'] ? 'rotate-90' : ''}`} />
                                </p>
                            </Link>
                            <ul className={`nav nav-treeview ${openMenus['boxx5'] ? 'ver' : ''}`}>
                                <li className="nav-item">
                                    <Link to="/estadisica_1" className="nav-link" onClick={(e) => handleNavigation('/estadisica_1', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Costos por Patente</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/inicio" className="nav-link" onClick={(e) => handleNavigation('/inicio', e)}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inicio</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
}
