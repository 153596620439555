import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'
import { format } from "date-fns";
import moment from 'moment';

export default function MenuC() {
    var que = localStorage.getItem('us');
    if(que!= null){
      que = que.replace(/"/g,'');
    }
    
    const hoy = new Date();
// tabla produccion
const [produc, setProduc] = useState([]);  
// Guardar variables
const [combi, setCombi] = useState([]); 
const [F1, setF1] = useState([]); 
const [F2, setF2] = useState([]); 

// Variables tablas
const [tabpe, setTab_pe] = useState([]); // Detalle Petróleo

// Tabla detalle Petroleo totales
var a = 0;
var a1 = 0;
var b11 = 0;
const [b1, setB1] = useState(0);
const [a11, setA11] = useState(0);

// Inputs del formulario
const [inputs, setInputs] = useState({});
// Modal de error
const swal_error = () => {
    withReactContent(Swal).fire({
      title: "Error",
      text: "Faltan campos por Rellenar",
      icon: "error",
    });
  };
// Registra las entradas de los inputs
const handleChange = (event) => {

    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
    setInputs((values) => ({ ...values, ["digitador"]: que2 }));
    setInputs((values) => ({ ...values, ["cond"]: que2 }));
    setInputs((values) => ({ ...values, ["cod"]: "detalleP" }));
    setInputs((values) => ({ ...values, ["codigo"]: "detalleP" }));



    
  };

  // Se llaman las Funciones
useEffect(() => {
  
  
    // Carga todo
     getAll();
  }, [F1, F2]);

// Guarda fecha1
const f1 = (event) => {
  const value = event.target.value;
  setF1(value);
  
};

// Guarda combobox
const f2 = (event) => {
  
  const value = event.target.value;
  setF2(value);
  
};



const handleSubmit = (event) => {
    event.preventDefault();
    enviar();
    //console.log(inputs);
  };

  function enviar(){
    axios
   .post(rialURL + "sanvi/api/guias/ver",inputs)
   .then(function (response) {
    setTab_pe(response.data);
     //console.log(response.data);
     response.data.map((cli) => {
      b11 += parseInt(cli.litros);
      
    });
    setB1(b11);
    console.log(response.data);
     
   });
  }

  function getAll(){
     // Get Tabla produccion 
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=getDoc2&con="+que+"&f1="+F1+"&f2="+F2)
 //.get(rialURL + "sanvi/api/guias/ver?cod=getDoc2&con=Vega%20Ivan&f1=2024-07-01&f2=2024-07-30")
   .then(function (response) {
     console.log(response.data);
     console.log(F1);
     setProduc(response.data);
     
     setA11(response.data.length);
     console.log(response.data.length)
   });
  }

  return (
    <div id="bod" className="content-wrapper">
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Producción y Petróleo</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div>

                      <div> 
                  <div className="form-group">
                      <label htmlFor="dia">Fecha 1</label>
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control inputi"
                          id="fecha1"
                          name="fecha1"
                          onChange={handleChange}
                          onBlur={f1}
                          required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="dia">Fecha 2</label>
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control inputi"
                          id="fecha2"
                          name="fecha2"
                          onChange={handleChange}
                          onBlur={f2}
                          required/>
                      </div>
                    </div>

                    </div>

                    

                    

                    

                  </div>
                </div>

                <div className="card-footer">
                  <button id="sos" className="btn btn-primary">
                    Consultar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro"><strong>{combi}</strong> Entre las fechas <strong>{moment(F1).format('DD/MM/yyyy')}</strong> y <strong>{moment(F2).format('DD/MM/yyyy')}</strong> </h3>
                </div>
                <div className="card-body">
                <div className="card-body">
        <div className="row cc">
        <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-success">
           
              <div className="inner">
                <h3>
                {new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(a11)}
                </h3>
                <p> Guias </p>
                
              </div>
              <div className="icon">
                {/* <i className="ion ion-stats-bars" /> */}
              </div>
              <a href="#" className="small-box-footer">
                 <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
              <h3>{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(b1)}</h3>

                <p>Litros </p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-bag" /> */}
              </div>
              <a href="#" className="small-box-footer">
               <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>

        </div>
      </div> 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Tabla Producción</h3>
              </div>
              <div className="card-body">

              <div className="card-body table-responsive p-0" style={{height: 300,overflow: "auto"}}>
      <table className="table table-head-fixed text-nowrap">
        <thead>
          <tr>
              <th>Fecha</th>
              <th>Guia</th>
              <th>Uubicacion</th>
              <th>Material</th>
              <th>Cantidad</th>
              <th>Destino</th>
              <th>Subdestino</th>
              <th>Conductor</th>
              
          </tr>
        </thead>
        <tbody>
        {produc.map((cli, key) =>
          <tr className="tede" id ="kk" key={key}> 
             {/* Litros */}
             <span style={{display:'none'}}>{a += parseInt(cli.capacidad)}</span>
             
             
             
             
              <td>{moment(cli.fecha).format('DD/MM/yyyy')}</td>
              <td>{cli.guia}</td>
              <td>{cli.ubicacion}</td>
              <td>{cli.material}</td>
              <td>{cli.capacidad}</td>
              <td>{cli.destino}</td>
              <td>{cli.subdestino}</td>
              <td>{cli.conductor}</td>
              
            </tr>)}
        
          
        </tbody>
        <thead>
          <tr>
              <th>Totales:</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{a}</th>
              <th></th>
              <th></th>
              <th></th>
              
              
          </tr>
        </thead>
      </table>
    </div>
                  
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Tabla Petróleo</h3>
              </div>
              <div className="card-body">

              <div className="card-body table-responsive p-0" style={{height: 300,overflow: "auto"}}>
      <table className="table table-head-fixed text-nowrap">
        <thead>
          <tr>
              <th>Fecha</th>
              <th>Patente</th>
              <th>Litros</th>
              <th>Vale</th>
              <th>Conductor</th>
              <th>Valor</th>
              <th>Odometro</th>
              
          </tr>
        </thead>
        <tbody>
        {tabpe.map((cli, key) =>
          <tr className="tede" id ="kk" key={key}> 
             {/* Litros */}
             <span style={{display:'none'}}>{a1 += parseInt(cli.litros)}</span>
             
             
             
             
              <td>{moment(cli.fecha).format('DD/MM/yyyy')}</td>
              <td>{cli.patente}</td>
              <td>{cli.litros}</td>
              <td>{cli.vale}</td>
              <td>{cli.conductor}</td>
              <td>{cli.valor}</td>
              <td>{cli.odometro}</td>
              
            </tr>)}
        
          
        </tbody>
        <thead>
          <tr>
              <th>Totales:</th>
              <th></th>
              <th>{a1}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              
              
          </tr>
        </thead>
      </table>
    </div>
                  
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>














  </div>
  )
}
