/*import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;*/

import React from 'react';
import {lazy} from 'react';
import Header from './components/Header';
import Aside from './components/Aside';
import Content from './components/Content';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch, Redirect,  } from 'react-router-dom';
import Login from './components/Login';
import "react-big-calendar/lib/css/react-big-calendar.css" 
import Users from './components/Users';
import Ubicaciones from './components/Ubicaciones';
import Conductores from './components/Conductores';
import Patentes from './components/Patentes';
import Destinos from './components/Destinos';
import Materiales from './components/Materiales';
import Petroleo from './components/Petroleo';
import Gastos from './components/Gastos';
import Distancias from './components/Distancias';
import Produccion from './components/Produccion';
import E_patente from './components/E_patente';
import Detalle_p from './components/Detalle_p';
import Subdes from './components/Subdes';
import Todo from './components/Todo';
import Aside2 from './components/Aside2';
import MenuC from './components/MenuC';


let redirectToUrl;
let redirectToUrls;
let aside;
let log = localStorage.getItem("log");
var lvl = localStorage.getItem("lvl");
export const rialURL='https://sanvi.codecland.com/';
// https://sanvi.codecland.com/
// https://app.chileinmune.cl/ 
// http://localhost:80/

// Nombre Digitador
var que2 = localStorage.getItem("us");
if (que2 != null) {
  que2 = que2.replace(/"/g, "");
}
export var que2;


if (log !='"activo"') //Si no ha iniciado sesión, lo vuelve al login. 
{
  redirectToUrl = <Redirect to='/login'/>;
}

if (log =='"activo"') //Si la sesión está activa, abre la ventana a ver 
{
  redirectToUrls = <Redirect to={'/inicio'}/>;
}

if (lvl == '"2"') //Si la sesión está activa, abre la ventana a ver 
{
  aside = <Aside2 />;
}else{
  aside = <Aside />;
}



export default function App() {
  return (
    <Router>

    {redirectToUrl}
      <Switch>

        <Route exact path='/login'>
          <Login />
          
          {redirectToUrls}
        </Route>
        

        <Route exact path='/contenido'>
         <Header />
          {aside}
          <Content />
          <Footer />
        </Route>

        <Route exact path='/ubicaciones'>
         <Header />
          {aside}
          <Ubicaciones />
          <Footer />
        </Route>

        <Route exact path='/conductores'>
         <Header />
          {aside}
          <Conductores />
          <Footer />
        </Route>
        
        <Route exact path='/patentes'>
         <Header />
          {aside}
          <Patentes />
          <Footer />
        </Route>

        <Route exact path='/destinos'>
         <Header />
          {aside}
          <Destinos />
          <Footer />
        </Route>

        <Route exact path='/materiales'>
         <Header />
          {aside}
          <Materiales />
          <Footer />
        </Route>

        <Route exact path='/petroleo'>
         <Header />
          {aside}
          <Petroleo />
          <Footer />
        </Route>

        <Route exact path='/gastos'>
         <Header />
          {aside}
          <Gastos />
          <Footer />
        </Route>

        <Route exact path='/distancias'>
         <Header />
          {aside}
          <Distancias />
          <Footer />
        </Route>

        <Route exact path='/produccion'>
         <Header />
          {aside}
          <Produccion />
          <Footer />
        </Route>

        <Route exact path='/produccion'>
         <Header />
          {aside}
          <Produccion />
          <Footer />
        </Route>

        <Route exact path='/estadisica_1'>
         <Header />
          {aside}
          <E_patente />
          <Footer />
        </Route>

        <Route exact path='/inicio'>
         <Header />
          {aside}
          <Todo />
          <Footer />
        </Route>

        

        <Route exact path='/detalleP'>
         <Header />
          {aside}
          <Detalle_p />
          <Footer />
        </Route>

        <Route exact path='/subi'>
         <Header />
          {aside}
          <Subdes />
          <Footer />
        </Route>

       

        <Route exact path='/usuarios'>
         <Header />
          {aside}
          <Users />
          <Footer />
        </Route>

        <Route exact path='/menuC'>
         <Header />
          {aside}
          <MenuC />
          <Footer />
        </Route>

      </Switch>
      
      
    
    </Router>
  )
}


